<template>
    <div>
        <b-row v-if="errors.length > 0">
            <b-col cols="12">
                <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key ="index" variant="danger" dismissible>{{item.message}}</b-alert>
            </b-col>
        </b-row>
        <div v-if="state === 'loading'">
            <b-row align-h="center" align-v="center" class="full-row" >
                <b-spinner  style="width: 3rem; height: 3rem;" type="grow" label="Loading..."></b-spinner>
            </b-row>
        </div>
        <div v-if="state === 'show'">
        <b-row>
            <b-col cols="12">
                <p class="text-center">Please select which I/O's to show for the device: {{currentDevice}}</p>
            </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button block @click="selectAll()" variant="primary">Enable All</b-button>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-button block @click="deselectAll()" variant="red">Disable All</b-button>
          </b-col>
        </b-row>
    <b-row no-gutters align-h="end" class="pl-3 pr-3 mt-0 ">
      <span class="top-section-info mb-1">Last Active: {{lastEventDate !== null ? lastEventDate : 'No Data Found' | shortDateTimeFilter}}</span>
    </b-row>
    <b-row v-for="(item, index) in devices" :key="index" class="mt-0">
      <b-col cols="12" :class="index === 0 ? 'mt-0' : 'mt-2'">
        <b-card class="card-custom-padding text-right clickable" @click="item.display = !item.display"
        :class="item.display === true ? item.type === 'Input' ? 'primary-background' : 'secondary-background' : 'gray-background'">
            <b-row align-h="between" align-v="center" class="mt-0">
              
              <div>
            <img v-if="item.deviceType === 'Analog'" class="icon-img mr-1" src="@/assets/cog-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Digital'" class="icon-img mr-1" src="@/assets/bandcamp-brands.svg" alt="" />
            <img v-if="item.deviceType === 'Virtual'" class="icon-img mr-1" src="@/assets/cogs-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Relay'" class="icon-img mr-1" src="@/assets/sliders-h-solid.svg" alt="" />
            <img v-if="item.deviceType === 'Procedure'" class="icon-img mr-1" src="@/assets/wrench-solid.svg" alt="" />
            <span class="small-text">{{item.deviceType}}</span>
              </div>
              {{item.description}}
            </b-row>
          <h4>{{item.reading}} {{item.unitOfMeasurements !== 'Undefined' ? item.unitOfMeasurements :  ''}}</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-0 mb-3">
      <b-col cols="12" md="12" lg="6" class="mt-3">
        <b-card class="card-custom-padding tertiary-background text-center clickable" @click="saveChanges()">
          <p>Save</p>
        </b-card>
      </b-col>
    </b-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
  },
  data:() => ({
    state: 'loading',
        currentDevice: null,
        lastEventDate: null,
        devices: [],
        errors: []
  }),
  created(){
    this.$store.commit('changeScreen', 'deviceManager')
    if (localStorage.getItem('selectedDevice') !== null) {
        this.deviceSelected = {
            untId: localStorage.getItem('selectedDevice'),
            untName: localStorage.getItem('selectedDeviceName'),
            untSerial: localStorage.getItem('selectedDeviceSerial')
        }
    }
            this.errors = []
    this.getCompleteUnitList()
    .then((response) => {
      this.state = 'show'
      this.lastEventDate = response.data.key
      this.devices = response.data.value
    })
    .catch(() => {
      this.state = 'show'
      this.errors.push({message: 'Could not retrieve list of available Inputs and Outputs'})
    })
  },
  methods: {
    ...mapActions(['getCompleteUnitList', 'saveCompleteList']),
    saveChanges(){
      this.state = 'loading'
      let request = []
            this.errors = []
      for (let i = 0; i < this.devices.length; i++) {
        let item = {
          inpId: this.devices[i].inpId,
          outId: this.devices[i].outId,
          untId: this.devices[i].untId,
          display: this.devices[i].display
        }
        request.push(item)
      }

      
      this.$store.commit('setSaveCompleteListRequest', request)

      this.saveCompleteList()
      .then(() => {
        this.state = 'show'
        this.$store.commit('changeScreen', 'home')
        this.$router.push({path: '/home'})
      })
      .catch(() => {
      this.state = 'show'
        this.errors.push({message: 'Could not save list details'})
      })
    },
    deselectAll(){
      this.devices.forEach(item => {
        item.display = false
      });
    },
    selectAll(){
      this.devices.forEach(item => {
        item.display = true
      });
    }
  }
}
</script>
